// Blue colors
$MAIN_BLUE_COLOR: #2a8cfa;
$MAIN_BLUE_HOVER_COLOR: #2a7cfa;

// Red colors
$MAIN_RED_COLOR: #F44336;

// Action Button
$ACTION_BUTTON_HOVER_COLOR: rgba(0, 0, 0, .15);
$ACTION_BUTTON_DISABLED_COLOR: rgba(0, 0, 0, .05);
$ACTION_BUTTON_TRANSITION: .15s background-color;
$ACTION_BUTTON_SIZE: 45px;
$ACTION_BUTTON_RADIUS: 50%;

$ACTION_MOVE_BUTTON_SIZE: 40px;

// List Item
$LIST_ITEM_TRANSITION: .15s background-color;
$LIST_ITEM_RADIUS: 12px;
$LIST_ITEM_HOVER_COLOR: #f8f9fa;
$LIST_ITEM_MARGIN_BOTTOM: 5px;
$LIST_ITEM_PADDING: .3rem .75rem;
$LIST_ITEM_CHILD_PADDING: .2rem 0rem;

$tooltip-text-color: white;
$tooltip-background-color: black;
$tooltip-margin: 30px;
$tooltip-arrow-size: 6px;

// Forms
$FORM_BACKGROUND_GREY: #707070;
$FORM_LABEL_GREY: #656B73;

// Map
$streetcloud-red-color: #F44336;
$streetcloud-red-hover-color: #DB3C30;
$streetcloud-blue-color: #1482FF;
$streetcloud-blue-hover-color: #07f;
$streetcloud-green-color: #47D462;
$streetcloud-green-hover-color: #43cc5d;
$streetcloud-grey-color: #707070;
$streetcloud-orange-color: #F78200;
$streetcloud-light-grey-color: #D1D1D1;
$streetcloud-whisper-color: #ececec;
$streetcloud-solitude-lighter-color: #f0f2f5;
$streetcloud-solitude-darker-color: #e9ecef;
$streetcloud-default-border-radius: 8px;
