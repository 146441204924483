#Loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f4f6; }


.by_parkeagle {
  position: fixed;
  bottom: 50px; }

.by_parkeagle_text {
  color: #8d929d;
  font-weight: 600; }
